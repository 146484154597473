import React from 'react'
import './CuadernilloCell.css'

const CuadernilloCell = ({ cuadernillo }) => {
  if (!cuadernillo) return ''

  return (
    <a
      className='InformationTable-cell-cuadernillo'
      href={cuadernillo.url}
      target='_blank'
    >
      {cuadernillo.title}
    </a>
  )
}

export default CuadernilloCell
